import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { WidgetItem } from '@app/shared/component/widget-layout/widget-item.directive';
import {
  ProductSalesMetricKey,
  ProductSalesWidgetGraphMetric,
  ProductType,
  ProductTypeStrict,
  ProductTypeString,
  ReasonLink,
  ReasonStrict,
  ReasonString,
  StrictDictionary,
} from '@typings';

export const PRODUCT_TYPE: ProductTypeStrict = {
  PRODUCT: 'PRODUCT',
  TECH_CARD: 'TECH_CARD',
  SERVICE: 'SERVICE',
  SEMIPRODUCT: 'SEMIPRODUCT',
  MODIFIER: 'MODIFIER',
};

export const PRODUCT_TYPE_STRING: ProductTypeString = {
  PRODUCT: 'Товары и техкарты',
  TECH_CARD: 'Ингредиенты',
  SERVICE: 'SERVICE',
  SEMIPRODUCT: 'Полуфабрикаты',
  MODIFIER: 'Модификаторы',
};

export const getProductType = (productType: string) => Object.keys(PRODUCT_TYPE).find((key) => key === productType)!;

export const getProductTypeString = (productType: string): string | undefined => {
  const type = getProductType(productType);

  return type && PRODUCT_TYPE_STRING[type];
};

export const PRODUCT_TYPE_LIST: AutocompleteOption<ProductType>[] = Object.keys(PRODUCT_TYPE).map((type) => ({
  id: type,
  label: PRODUCT_TYPE_STRING[type],
  type: 'item',
  data: type,
}));

export const WEIGHT_TYPE_PIECE: AutocompleteOption<boolean> = {
  id: 'piece',
  label: 'Поштучный',
  type: 'item',
  data: false,
};

export const WEIGHT_TYPE_WEIGHABLE: AutocompleteOption<boolean> = {
  id: 'weighable',
  label: 'Весовой',
  type: 'item',
  data: true,
};

export const WEIGHT_TYPE_LIST: AutocompleteOption<boolean>[] = [WEIGHT_TYPE_PIECE, WEIGHT_TYPE_WEIGHABLE];

export const STATISTIC_WIDGET_GRAPH_METRICS: StrictDictionary<ProductSalesWidgetGraphMetric, ProductSalesWidgetGraphMetric> = {
  profit: 'profit',
  revenue: 'revenue',
  prime: 'prime',
  sales: 'sales',
  timestamp: 'timestamp',
};

export const STATISTIC_WIDGET_GRAPH_METRICS_NAMES: StrictDictionary<string, ProductSalesWidgetGraphMetric> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  prime: 'Себестоимость',
  sales: 'Продажи',
  timestamp: 'timestamp',
};

export const PRODUCT_SALES_WIDGET_METRICS: StrictDictionary<string, ProductSalesMetricKey> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  primePrice: 'Себестоимость',
  sales: 'Продажи',
};

export const PRODUCT_SALES_WIDGET_METRICS_UNITS: StrictDictionary<boolean, ProductSalesMetricKey> = {
  revenue: true,
  profit: true,
  primePrice: true,
  sales: false,
};

export type ProductAnalyticWidgetName = 'todayWidget' | 'salesWidget';
export const PRODUCT_ANALYTIC_WIDGETS_NAMES: Record<ProductAnalyticWidgetName, ProductAnalyticWidgetName> = {
  todayWidget: 'todayWidget',
  salesWidget: 'salesWidget',
};

export const PRODUCT_ANALYTIC_TODAY_WIDGET: WidgetItem = {
  name: PRODUCT_ANALYTIC_WIDGETS_NAMES.todayWidget,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const PRODUCT_ANALYTIC_WIDGET: WidgetItem = {
  name: PRODUCT_ANALYTIC_WIDGETS_NAMES.salesWidget,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const PRODUCT_ANALYTIC_WIDGETS_CONFIG: WidgetItem[] = [PRODUCT_ANALYTIC_TODAY_WIDGET, PRODUCT_ANALYTIC_WIDGET];

export const REASON_TYPE_NAME: ReasonStrict = {
  ARRIVAL_DOCUMENT: 'ARRIVAL_DOCUMENT',
  INVENTORY_DOCUMENT: 'INVENTORY_DOCUMENT',
  LEAVING_BY_PARENT_ITEM_DOCUMENT: 'LEAVING_BY_PARENT_ITEM_DOCUMENT',
  LEAVING_DOCUMENT: 'LEAVING_DOCUMENT',
  MOVE_DOCUMENT: 'MOVE_DOCUMENT',
  NONE: 'NONE',
  PACK: 'PACK',
  RETURN: 'RETURN',
  RETURN_DOCUMENT: 'RETURN_DOCUMENT',
  SOLD: 'SOLD',
  UNPACK: 'UNPACK',
};

export const REASON_TYPE_NAME_STRING: ReasonString = {
  ARRIVAL_DOCUMENT: 'Поставка',
  INVENTORY_DOCUMENT: 'Инвентаризация',
  LEAVING_BY_PARENT_ITEM_DOCUMENT: 'Списание',
  LEAVING_DOCUMENT: 'Списание',
  MOVE_DOCUMENT: 'Перемещение',
  NONE: 'NONE',
  PACK: 'PACK',
  RETURN: 'RETURN',
  RETURN_DOCUMENT: 'RETURN_DOCUMENT',
  SOLD: 'Продажа',
  UNPACK: 'UNPACK',
};

export const REASON_TYPE_NAME_ROUTE_STRING: ReasonString = {
  ARRIVAL_DOCUMENT: 'arrivals',
  INVENTORY_DOCUMENT: 'inventories',
  LEAVING_BY_PARENT_ITEM_DOCUMENT: 'leavings',
  LEAVING_DOCUMENT: 'leavings',
  MOVE_DOCUMENT: 'movings',
  NONE: 'NONE',
  PACK: 'PACK',
  RETURN: 'RETURN',
  RETURN_DOCUMENT: 'RETURN_DOCUMENT',
  SOLD: 'orders-report',
  UNPACK: 'UNPACK',
};

export const REASON_TYPE_LINK: ReasonLink = {
  ARRIVAL_DOCUMENT: true,
  INVENTORY_DOCUMENT: true,
  LEAVING_BY_PARENT_ITEM_DOCUMENT: false,
  LEAVING_DOCUMENT: true,
  MOVE_DOCUMENT: true,
  NONE: false,
  PACK: false,
  RETURN: false,
  RETURN_DOCUMENT: false,
  SOLD: false,
  UNPACK: false,
};
