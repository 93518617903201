import { gql } from 'apollo-angular';

export const queryUsers = gql`
  query users($filter: UserFilterInput, $pageRequestInput: PageRequestInput!) {
    users(filter: $filter, pageRequestInput: $pageRequestInput) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        id
        accountId
        workEmail
        firstName
        lastName
        middleName
        workPhone
        vatin
        snils
        imageId
        medicalBookExpiresAt
        dateOfBirth
        dateOfEmployment
        address
        roles
        active
        pin
        inviteStatus
      }
    }
  }
`;

export const queryIamUser = gql`
  query iamUser($organizationId: UUID!) {
    iamUser(organizationId: $organizationId) {
      id
      workEmail
      firstName
      lastName
      middleName
      workPhone
      vatin
      snils
      imageId
      medicalBookExpiresAt
      dateOfBirth
      dateOfEmployment
      address
      pin
      roles
      active
    }
  }
`;

export const queryUser = gql`
  query user($id: UUID!) {
    user(id: $id) {
      id
      workEmail
      firstName
      lastName
      middleName
      workPhone
      vatin
      imageId
      snils
      medicalBookExpiresAt
      dateOfBirth
      dateOfEmployment
      address
      roles
      active
      pin
    }
  }
`;

export const mutationInviteUser = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      status {
        errorMessage
        result
      }
      createdUser {
        id
        workEmail
        firstName
        lastName
        middleName
        workPhone
        vatin
        snils
        imageId
        medicalBookExpiresAt
        dateOfBirth
        dateOfEmployment
        address
        active
      }
    }
  }
`;

export const mutationUpdateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status {
        errorMessage
        result
      }
      updatedUser {
        id
        workEmail
        firstName
        lastName
        middleName
        workPhone
        vatin
        snils
        imageId
        medicalBookExpiresAt
        dateOfBirth
        dateOfEmployment
        address
        roles
        active
      }
    }
  }
`;

export const mutationAddUserRoles = gql`
  mutation addUserRoles($id: UUID!, $roleIds: [UUID!]!) {
    addUserRoles(id: $id, roleIds: $roleIds) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationRemoveUserRoles = gql`
  mutation removeUserRoles($id: UUID!, $roleIds: [UUID!]!) {
    removeUserRoles(id: $id, roleIds: $roleIds) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationDeleteUser = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(id: $id) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationChangeUserPin = gql`
  mutation refreshUserPin($userId: UUID!) {
    refreshUserPin(userId: $userId) {
      pin
      status {
        errorMessage
        result
      }
    }
  }
`;
